import '../components/loader';

exports.photoCheck = function () {
    const loader = document.getElementById('loading-spinner');
    const sigImage = document.getElementById('signature-image');
    const continueButton = document.getElementById("check-form").querySelector('.govuk-button');
    const spinner = new GOVUK.Loader();

    if (!isImageLoadedOrError(sigImage)) {
        showElement(loader);
        hideElement(sigImage);
        disableButton(continueButton);
        spinner.init({
            container: "loading-spinner",
            label: true
        });
        spinner.updateMessage('Loading...');
        sigImage.addEventListener('load', imageOnLoad);
        setTimeout(() => enableButton(continueButton), 5000);
    }

    function imageOnLoad() {
        hideElement(loader);
        spinner.stop();
        showElement(sigImage);
        enableButton(continueButton);
    }

    function disableButton(button) {
        button.setAttribute('disabled', '');
        button.setAttribute('aria-disabled', true);
    }

    function enableButton(button) {
        button.removeAttribute('disabled');
        button.removeAttribute('aria-disabled');
    }

    function hideElement(element) {
        element.classList.add('hidden');
    }

    function showElement(element) {
        element.classList.remove('hidden');
    }

    function isImageLoadedOrError(imgElement) {
        // 'complete' is true if the image has loaded or is broken
        // Many examples on the web add (naturalHeight !== 0) here to exclude broken images
        // but we want to turn off the spinner and re-enable the continue button in that case
        // so we haven't added it here.
        return imgElement.complete;
    }
}
