/**
 *  This file is similar to:
 *    - photo-upload.js
 *    - upload-documents.js
 *    - upload-documents-pre-selected.js
 *  Bugs found here likely have to be fixed there too.
 *  Possibility of commonizing these in the future.
 *
 *  This file (photo-upload.js) is currently used by:
 *    - VAC photo upload
 *      frontend/src/forms/provide-photo/provide-photo.njk
 *    - Postal and Proxy signature upload
 *      frontend/src/forms/absent-voting/upload-an-image.njk
 */

import '../components/loader';
import {urlToBucketKey as s3UrlToBucketKey} from "../s3";

exports.photoUpload = function (
    noPreviousUpload,
    fileUploadId,
    errorSummaryId,
    maxFileSizeInMB,
    minFileSizeInKB,
    acceptedFileExtensions,
    error__none_selected,
    error__too_big,
    error__too_small,
    error__filetypes,
    error__unknown,
    error__abort,
    screen_reader_alert__file_added,
    screen_reader_alert__file_removed,
    allowNoUpload = false,
    continueButtonTextOnUpload = undefined
) {
    preventDropFilesExceptOnDropArea();
    addScreenReaderFileStatusAlert();

    const continueForm = document.getElementById("continue-form");
    const nextButton = continueForm.querySelector('button[name="submit-button"]');
    disableNextButtonOnRemoveFile();

    // The js code below only runs when the upload form is shown.
    if (!noPreviousUpload) {
        return;
    }

    const errorMessages = {
        error__none_selected,
        error__too_big,
        error__too_small,
        error__filetypes,
        error__unknown,
        error__abort
    };

    const dropAreaWrapper = document.getElementById('drop-area-wrapper');
    dropAreaWrapper.classList.remove("hidden");

    const spinner = new GOVUK.Loader();

    const s3UploadForm = document.getElementById('file-upload-form');
    s3UploadForm.classList.add("hidden");
    var fileHasBeenUploadedOk = false;

    const dropAreaId = 'drop-area';
    const dropArea = document.getElementById(dropAreaId);
    const upload = document.getElementById(fileUploadId);

    const uploadButtonWrapperId = "upload-button-wrapper";
    const uploadButton = document.querySelector('button[name="upload-button"]');

    enableNextButton();

    const jsErrorSummary = document.getElementById(errorSummaryId);
    const jsErrorList = jsErrorSummary.querySelector("ul");

    uploadButton.addEventListener('click', function (ev) {
        upload.click();
    });
    upload.addEventListener('change', uploadFile);
    dropArea.addEventListener('drop', updateFiles);
    nextButton.addEventListener('click', onNextClick);

    function updateFiles(event) {
        dropAreaWrapper.classList.remove("drop-area-wrapper--active");
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length === 0) {
            // You can trigger this event with no files by dropping things like other page elements
            return;
        } else if (files.length !== 1) {
            renderErrorAndResetPageControls(`Please only upload one file.`)
            return;
        }
        upload.files = files;
        uploadFile(event);
    }

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, event => {
            event.preventDefault();

            // Adds active state class to the drop area when item is dragged into
            // the drop zone.
            if (eventName === 'dragenter' || eventName === 'dragover') {
                dropAreaWrapper.classList.add("drop-area-wrapper--active");
            }

            // Removes active state class to the drop area when item is dragged out
            // from the drop zone.
            if (eventName === 'dragleave') {
                dropAreaWrapper.classList.remove("drop-area-wrapper--active")
            }
        });
    });

    function trackSignatureUploadError(error) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Signature upload error',
            eventAction: error,
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': error,
            },
        })
    }

    function onNextClick(event) {
        if (fileHasBeenUploadedOk || allowNoUpload) {
            return true;
        } else {
            // The user has clicked "continue" without selecting a file
            event.preventDefault();
            renderErrorAndResetPageControls(errorMessages.error__none_selected);
            trackSignatureUploadError("no_image_selected");
        }
    }

    function uploadFile(event) {
        event.preventDefault();

        const file = upload.files[0];
        const fileIsValid = validateFile(file);
        if (fileIsValid) {
            disableNextButton();
            dropAreaWrapper.classList.add("hidden");
            spinner.init({
                container: "loading-spinner",
                label: true
            });

            // testing browser support. if no support for the required js APIs
            // the form will just be posted naturally with no progress showing.
            var xhr = new XMLHttpRequest();
            if (!(xhr && ('upload' in xhr) && ('onprogress' in xhr.upload)) || !window.FormData) {
                s3UploadForm.submit();
                return;
            }

            xhr.upload.onprogress = progressHandler;
            xhr.onload = s3UploadCompleteHandler.bind(this, xhr, file.name);
            xhr.onerror = xhrErrorHandler;
            xhr.onabort = xhrAbortHandler;

            const formData = new FormData(s3UploadForm);
            // set to "" so not redirected automatically
            formData.set("success_action_redirect", "");
            xhr.open('POST', s3UploadForm.action);
            xhr.send(formData);
        }
        return false;
    }

    function validateFile(file) {
        if (!file) {
            renderErrorAndResetPageControls(errorMessages.error__none_selected);
            trackSignatureUploadError("no_image_selected");
            return false;
        }
        if (file.size > maxFileSizeInMB * 1000000) {
            renderErrorAndResetPageControls(errorMessages.error__too_big);
            trackSignatureUploadError("image_too_big");
            return false;
        }
        if (file.size < minFileSizeInKB * 1000) {
            renderErrorAndResetPageControls(errorMessages.error__too_small);
            trackSignatureUploadError("image_too_small");
            return false;
        }
        const nameLower = file.name.toLowerCase();
        if (!acceptedFileExtensions.find(ext => nameLower.endsWith(ext))) {
            renderErrorAndResetPageControls(errorMessages.error__filetypes);
            trackSignatureUploadError("invalid_file_type");
            return false;
        }
        clearErrorSummaryItems();
        return true;
    }

    function progressHandler(e) {
        var progress = Math.floor((e.loaded / e.total) * 100);
        spinner.updateMessage(`Uploading... ${progress}%`);
        spinner.updateProgress(progress);
    }

    // Called when the upload to S3 has completed
    function s3UploadCompleteHandler(uploadXhr, fileName) {
        try {
            spinner.updateMessage(`Processing`);
            if (uploadXhr.status !== 204) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from S3: " + uploadXhr.status);
            }

            // Tell the backend the name of the file that was just uploaded,
            // so the session state can be updated.
            // This allows us to avoid S3 "list" ops, which don't scale to high load.
            //
            // If the client browser fails or goes offline between the above op and
            // this then the image info will not get added to the session, and they
            // will need to upload it again

            // S3 can change the provided filename, for example spaces are trimmed.
            // We therefore get the final bucket + key info from the Location header
            const s3Location = uploadXhr.getResponseHeader("location");
            const bucketKey = s3UrlToBucketKey(s3Location);

            // browser xhr support was already tested above
            var xhr = new XMLHttpRequest();

            xhr.onload = sessionUpdateCompleteHandler.bind(this, xhr);
            xhr.onerror = xhrErrorHandler;
            xhr.onabort = xhrAbortHandler;

            xhr.open('POST', document.location);
            const formData = {
                "s3-upload-complete": "true",
                bucket: bucketKey.bucket,
                key: bucketKey.key
            };
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // It would be nice to use responseType = "document" here to
            // ask the browser to parse the html response into a DOM
            // but that prevents us from reading a 400 response in JSON, so we
            // set this to text and do the DOM parsing using innerHTML
            xhr.responseType = "text";
            xhr.send(new URLSearchParams(Object.entries(formData)).toString());

        } catch (e) {
            console.log(e);
            renderErrorAndResetPageControls(errorMessages.error__unknown);
        }
    }

    // Called when the after-upload session update XHR has completed
    function sessionUpdateCompleteHandler(xhr) {
        try {
            if (xhr.status === 400) {
                // Certain validation errors like image dimensions are checked server side
                // These will appear at key "photo-upload", see validateUploadedPhotoAndConvertIfRequired
                // Errors under other keys will be technical issues like failure to
                // parse the AWS response keys, so fall through to the generic error below.
                if (xhr.getResponseHeader("content-type") === "application/json"
                    || xhr.getResponseHeader("content-type") === "application/json; charset=utf-8") {
                    const body = JSON.parse(xhr.responseText)
                    if (body["errors"]
                        && body["errors"]["photo-upload"]
                        && body["errors"]["photo-upload"]["text"]) {

                        renderErrorAndResetPageControls(body["errors"]["photo-upload"]["text"]);
                        return
                    }
                }
            }

            if (xhr.status !== 200) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from backend: " + xhr.status);
            }

            const newHtml = xhr.responseText;
            if (!newHtml) {
                throw new Error("responseText null. Not supported browser?");
            }
            const elt = document.getElementById("upload-form-or-file-preview");
            elt.innerHTML = newHtml;

            resetPageControls();
            fileHasBeenUploadedOk = true;
            screenReaderFileAlert(screen_reader_alert__file_added);
            updateContinueButtonText();
            disableNextButtonOnRemoveFile();
        } catch (e) {
            console.log(e);
            renderErrorAndResetPageControls(errorMessages.error__unknown);
        }
    }

    // Error handler shared between the two XHR requests.
    // Set the UI to show a generic error message.
    // The buttons are set to enabled to allow the user to try again.
    function xhrErrorHandler(data) {
        console.error(data);
        renderErrorAndResetPageControls(errorMessages.error__unknown);
    }

    // Abort handler shared between the two XHR requests.
    // Set the UI to show a generic error message.
    // The buttons are set to enabled to allow the user to try again.
    function xhrAbortHandler() {
        renderErrorAndResetPageControls(errorMessages.error__abort);
    }

    function renderErrorAndResetPageControls(errorMessage) {
        dropAreaWrapper.classList.remove("hidden");
        upload.value = "";
        resetPageControls();

        addErrorSummaryItem(uploadButtonWrapperId, errorMessage);
        addErrorToUploadButton(errorMessage);

        jsErrorSummary.focus();
        jsErrorSummary.style.display = 'block';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // Hide the spinner and re-enable the next button
    function resetPageControls() {
        spinner.stop();
        enableNextButton();
        clearErrorSummaryItems();
    }

    function clearErrorSummaryItems() {
        // Some pages have an error summary which is hidden by default, some don't. Setting to none works in both cases.
        jsErrorSummary.style.display = 'none';
        jsErrorList.childNodes.forEach(function (child) {
            jsErrorList.removeChild(child)
        })
    }

    function enableNextButton() {
        nextButton.removeAttribute("disabled");
        nextButton.removeAttribute("aria-disabled");
    }

    function disableNextButton() {
        nextButton.setAttribute("disabled", "");
        nextButton.setAttribute("aria-disabled", true);
    }

    function addErrorSummaryItem(fieldId, errorMessage) {
        var listItem = document.createElement("li");
        var anchor = listItem.appendChild(document.createElement("a"));
        anchor.href = "#" + fieldId;
        anchor.textContent = errorMessage;

        jsErrorList.appendChild(listItem);
    }

    function addErrorToUploadButton(errorMessage) {
        const inputContainer = uploadButton.parentNode;
        const errorContainerId = uploadButtonWrapperId + "-error";

        let span = document.getElementById(errorContainerId);
        if (!span) {
            span = document.createElement("span");
            var hiddenSpan = span.appendChild(document.createElement("span"));
            span.id = errorContainerId;
            span.className = 'govuk-error-message';
            hiddenSpan.className = "govuk-visually-hidden";
            hiddenSpan.textContent = "Error:";
        }
        span.textContent = errorMessage;

        inputContainer.insertBefore(span, uploadButton);
        inputContainer.classList.add("govuk-form-group--error");
    }

    // Prevent users from dropping files on the page except on the
    // dropArea. If you drop a file on a page element which isn't expecting
    // it then most browsers will just open the file, which might be
    // very confusing for our users.
    function preventDropFilesExceptOnDropArea() {
        window.addEventListener("dragenter", function (e) {
            if (!dropArea.contains(e.target)) {
                e.preventDefault();
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
        }, false);

        window.addEventListener("dragover", function (e) {
            if (!dropArea.contains(e.target)) {
                e.preventDefault();
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
        });

        window.addEventListener("drop", function (e) {
            if (!dropArea.contains(e.target)) {
                e.preventDefault();
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
        });
    }

    // On page load, look for query params set by postback redirects and
    // display a screen reader alert if applicable
    function addScreenReaderFileStatusAlert() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.get("file-removed") === "true") {
            screenReaderFileAlert(screen_reader_alert__file_removed);
        }

        // no "file-added" case, as that's either handled directly in js,
        // see screen_reader_alert__file_added above, or we're no-js
    }

    function screenReaderFileAlert(alertMessage) {
        const alert = document.getElementById("hidden-alert-container");
        alert.innerHTML = alertMessage;
    }

    function updateContinueButtonText() {
        if (continueButtonTextOnUpload) {
            nextButton.textContent = continueButtonTextOnUpload;
        }
    }

    function disableNextButtonOnRemoveFile() {
        const removeFileButtons = document.getElementsByName('remove-file-button');
        removeFileButtons && removeFileButtons.forEach(button => {
            button.addEventListener('click', () => {
                disableNextButton();
            });
        });
    }
};
